@font-face {
  font-family: 'Inter';
  src: url('inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Black';
  src: url('inter/Inter-Black.ttf') format('truetype');
}


@font-face {
  font-family: 'Inter-Bold';
  src: url('inter/Inter-Bold.ttf') format('truetype');
}

html, body {
  background: url(../public/img/desk2.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  margin: 0;
  font-family: 'Inter';
}

h2, .info, h4 {
  margin-bottom: 10px;
  font-family: 'Inter-Bold';
}

.logos {
  margin-top: 2%;
  margin-left: 17%;
  display: inline-block;

}

.question{
  font-family: 'Inter-Bold';
}

img {
  height: 80px;
  width: auto;
}


.logos > * {
  margin-right: 8px;
}

.main {
  margin-left: 17%;
  max-width: 900px;
}

.main-info{
  max-width: 850px;
}

.info-form {
  margin-right: 10%;
}

@media (max-width: 1200px) {
  .main-info {
    max-width: 80%;
  }
  .main {
    margin-left: 10%;
    margin-right: 10%;
  }

  .logos {
    margin-top: 1%;
    margin-left: 10%;
    display: inline-block;
  
  }

}

h2 {
  margin-top: 20px;
}

@media (max-width: 700px) {
  img {
    height: 50px;
    width: auto;
  }
}

p, button {
  margin-top: 10px;
}

div {
  margin-top: 15px;
}

input, select {
  border-radius: 6px;
  width: 180px;
  height: 35px;
}


button {
  border: 0;
  line-height: 2.5;
  padding: 0 20px;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  border-radius: 6px;
  background-color: rgb(25, 150, 167);
}

button:hover {
  background-color: rgb(26, 195, 200);
}

#answer {
  margin-left: 1%;
}
